$white: #FFFFFF;
$black: #000000;
$grey: #7E8288;
$deloitte-green: #86BC25;

$green2: #C4D600;
$green4: #43B02A;
$green6: #046A38;
$green7: #2C5234;
$teal5: #0097A9;
$blue2: #62B5E5;
$blue3: #00A3E0;
$blue4: #0076A8;
$blue6: #012169;
$cool-gray2: #D0D0CE;
$cool-gray4: #BBBCBC;
$cool-gray7: #97999B;
$cool-gray9: #75787B;
$cool-gray11: #53565A;

$green1: #E3E48D;
$green5: #009A44;
$teal1: #DDEFE8;
$teal2: #9DD4CF;
$teal3: #6FC2B4;
$teal4: #00ABAB;
$teal6: #007680;
$teal7: #004F59;
$blue1: #A0DCFF;
$blue5: #005587;
$blue7: #041E42;
$cool-gray6: #A7A8AA;
$cool-gray10: #63666A;
$grey: #97999B;

$red: #DA291C;
$orange: #ED8B00;
$yellow: #FFCD00;


$body-bg: #E8EAE9;
$body-color: $black;
